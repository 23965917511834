import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import Questions from '../data/question-list';
import TestQuestions from '../data/test/question-list';

export default createStore({
  plugins: [createPersistedState()],
  state: {
    responses: [
      { id: 'q1', responseIndex: null },
      { id: 'q2', responseIndex: null },
      { id: 'q3', responseIndex: null },
      { id: 'q4', responseIndex: null },
      { id: 'q5', responseIndex: null },
      { id: 'q6', responseIndex: null },
      { id: 'q7', responseIndex: null },
      { id: 'q8', responseIndex: null },
      { id: 'q9', responseIndex: null },
      { id: 'q10', responseIndex: null },
      { id: 'q11', responseIndex: null },
      { id: 'q12', responseIndex: null },
      { id: 'q13', responseIndex: null },
      { id: 'q14', responseIndex: null },
      { id: 'q15', responseIndex: null },
      { id: 'q16', responseIndex: null },
      { id: 'q17', responseIndex: null },
    ],
    results: [],
  },
  mutations: {
    UPDATE_RESPONSE: (state, payload) => {
      const response = state.responses.find((updatedResponse) => updatedResponse.id === payload.id);
      Object.assign(response, payload);
    },
    UPDATE_RESULTS: (state, payload) => {
      state.results = payload;
    },
  },
  actions: {
  },
  modules: {
  },
  getters: {
    /* eslint-disable */
    getResponseByQuestionId: (state) => (questionId: string) => state.responses.find((response) => response.id === questionId),
    /* eslint-enable */
    getQuestions: () => {
      let questions = {};
      if (process.env.NODE_ENV === 'production') {
        questions = Questions;
      } else {
        questions = TestQuestions;
      }
      return questions;
    },
    getResults: (state) => state.results,
  },
});
