<template>
  <router-view />
</template>

<style lang="scss">
@import "./styles/global.scss";

.header-main + * {
  margin-top: $header-height;
}

</style>
