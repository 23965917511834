

import { defineComponent, PropType } from 'vue';
import PlainContent from '../types/PlainContent';

export default defineComponent({
  name: 'ContentBanner',
  props: {
    inverted: {
      type: Boolean,
      required: false,
    },
    content: {
      type: Object as PropType<PlainContent>,
      required: true,
    },
  },
});
