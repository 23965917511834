
import { defineComponent } from 'vue';
import ContentBox from './ContentBox.vue';
import { IntroductionContent } from '@/content/HomeContent';
import PlainContent from '@/types/PlainContent';

export default defineComponent({
  name: 'Introduction',
  components: {
    ContentBox,
  },
  setup() {
    const introductionContent: PlainContent = IntroductionContent;

    return {
      introductionContent,
    };
  },
});
