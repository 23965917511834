/* eslint-disable global-require */
import { Question } from '@/types/Questions';

const Questions: Question[] = [
  {
    id: 'q1',
    img: require('@/assets/images/questionnaire-assets/q1-questionnaire-desktop.png'),
    imgMobile: require('@/assets/images/questionnaire-assets/q1-questionnaire-mobile.jpg'),
    flag: 'yellow',
    label: 'How much of your day do you like to spend on your feet?',
    options: [
      {
        responseIndex: 1,
        label: 'I’d prefer to be seated for most of the day.',
      },
      {
        responseIndex: 2,
        label: 'I\'m happy to be on my feet for a couple of hours during the day.',
      },
      {
        responseIndex: 3,
        label: 'I don’t mind being on my feet for around 50% of my day.',
      },
      {
        responseIndex: 4,
        label: 'I like to be moving for most of the day, but don\'t want to get involved in physical work.',
      },
      {
        responseIndex: 5,
        label: 'I love being active and don’t mind getting involved in more physical work.',
      },
    ],
  },
  {
    id: 'q2',
    img: require('@/assets/images/questionnaire-assets/q2-questionnaire-desktop.png'),
    imgMobile: require('@/assets/images/questionnaire-assets/q2-questionnaire-mobile.jpg'),
    flag: 'orange',
    label: 'How comfortable would you be working in a noisy environment?',
    help: 'Use the scale below to state your preference.',
    options: [
      {
        responseIndex: 1,
        label: 'I am most comfortable in a quiet working environment. ',
      },
      {
        responseIndex: 2,
        label: 'I prefer to work in quiet spaces, but could tolerate noise in short bursts.',
      },
      {
        responseIndex: 3,
        label: 'I am open to working in noisy environments sometimes.',
      },
      {
        responseIndex: 4,
        label: 'I am happy to work around noise for most of my day.',
      },
      {
        responseIndex: 5,
        label: 'Noise doesn’t phase me! I’m used to working in loud places.',
      },
    ],
  },
  {
    id: 'q3',
    img: require('@/assets/images/questionnaire-assets/q3-questionnaire-desktop.png'),
    imgMobile: require('@/assets/images/questionnaire-assets/q3-questionnaire-mobile.jpg'),
    flag: 'yellow',
    label: 'Where would you prefer to spend your working day?',
    help: 'Use the scale below to state your preference. You can use the key for guidance.',
    options: [
      {
        responseIndex: 1,
        label: 'I’m happiest indoors.',
      },
      {
        responseIndex: 2,
        label: 'I would like to spend at least 75% of my time indoors.',
      },
      {
        responseIndex: 3,
        label: 'I enjoy variety, I’d like to have a mix of indoors and outdoors.',
      },
      {
        responseIndex: 4,
        label: 'I would like to spend at least 75% of my time outdoors.',
      },
      {
        responseIndex: 5,
        label: 'I love the outdoors.',
      },
    ],
  },
  {
    id: 'q4',
    img: require('@/assets/images/questionnaire-assets/q4-questionnaire-desktop.png'),
    imgMobile: require('@/assets/images/questionnaire-assets/q4-questionnaire-mobile.jpg'),
    flag: 'orange',
    label: 'Some of our working environments are dynamic, physically challenging and can involve risk, such as working around heavy machinery, in small spaces, or at height.',
    help: 'Are you comfortable working in these conditions?',
    options: [
      {
        responseIndex: 1,
        label: 'Yes',
      },
      {
        responseIndex: 2,
        label: 'No',
      },
    ],
  },
  {
    id: 'q5',
    img: require('@/assets/images/questionnaire-assets/q5-questionnaire-desktop.png'),
    imgMobile: require('@/assets/images/questionnaire-assets/q5-questionnaire-mobile.jpg'),
    flag: 'yellow',
    label: 'Use the scale below to let us know how comfortable you are with using technology.',
    help: 'You can use the key for guidance.',
    options: [
      {
        responseIndex: 1,
        label: "Best using a pen and paper; I'd require support using software and technology.",
      },
      {
        responseIndex: 2,
        label: 'I have a basic understanding of technology such as laptops or phones but would require support with software.',
      },
      {
        responseIndex: 3,
        label: 'I’m comfortable working with laptops, phones, tablets, and have a good understanding of software such as the office suite.',
      },
      {
        responseIndex: 4,
        label: 'I enjoy working with technology and feel that with support, I could learn to use advanced software.',
      },
      {
        responseIndex: 5,
        label: 'Technology is second nature to me. I can use advanced software and won’t be seen without a laptop or tablet.',
      },
    ],
  },
  {
    id: 'q6',
    img: require('@/assets/images/questionnaire-assets/q6-questionnaire-desktop.png'),
    imgMobile: require('@/assets/images/questionnaire-assets/q6-questionnaire-mobile.jpg'),
    flag: 'orange',
    label: 'Do you have an interest in working with heavy machinery? We have a variety you could get involved with, ranging from forklifts, to cranes, to excavators.',
    options: [
      {
        responseIndex: 1,
        label: 'Yes',
      },
      {
        responseIndex: 2,
        label: 'No',
      },
    ],
  },
  {
    id: 'q7',
    img: require('@/assets/images/questionnaire-assets/q7-questionnaire-desktop.png'),
    imgMobile: require('@/assets/images/questionnaire-assets/q7-questionnaire-mobile.jpg'),
    flag: 'yellow',
    label: 'Do you have an interest in working with tools?',
    options: [
      {
        responseIndex: 1,
        label: 'Yes – I’m great with tools and love getting stuck in!',
      },
      {
        responseIndex: 2,
        label: "No – Tools and handywork isn't something I'm interested in",
      },
    ],
  },
  {
    id: 'q8',
    img: require('@/assets/images/questionnaire-assets/q8-questionnaire-desktop.png'),
    imgMobile: require('@/assets/images/questionnaire-assets/q8-questionnaire-mobile.jpg'),
    flag: 'orange',
    label: 'Does the following statement apply to you?',
    help: 'I am a great problem solver; I’m in my element when working towards a goal that is supported by logic, or a proven process.',
    options: [
      {
        responseIndex: 1,
        label: 'Yes',
      },
      {
        responseIndex: 2,
        label: 'No',
      },
    ],
  },
  {
    id: 'q9',
    img: require('@/assets/images/questionnaire-assets/q9-questionnaire-desktop.png'),
    imgMobile: require('@/assets/images/questionnaire-assets/q9-questionnaire-mobile.jpg'),
    flag: 'yellow',
    label: 'Does the following statement apply to you?',
    help: 'I love working with numbers.',
    options: [
      {
        responseIndex: 1,
        label: 'Yes – I’ll be in my element',
      },
      {
        responseIndex: 2,
        label: 'No – that sounds tedious',
      },
    ],
  },
  {
    id: 'q10',
    img: require('@/assets/images/questionnaire-assets/q10-questionnaire-desktop.png'),
    imgMobile: require('@/assets/images/questionnaire-assets/q10-questionnaire-mobile.jpg'),
    flag: 'orange',
    label: 'Consider the following scenario:',
    help: 'Do you like writing reports and using written communication skills?',
    options: [
      {
        responseIndex: 1,
        label: 'Yes',
      },
      {
        responseIndex: 2,
        label: 'No',
      },
    ],
  },
  {
    id: 'q11',
    img: require('@/assets/images/questionnaire-assets/q11-questionnaire-desktop.png'),
    imgMobile: require('@/assets/images/questionnaire-assets/q11-questionnaire-mobile.jpg'),
    flag: 'yellow',
    label: 'Would you be interested in spending a lot of your day driving? For example, driving buses or HGVs.',
    options: [
      {
        responseIndex: 1,
        label: 'Yes',
      },
      {
        responseIndex: 2,
        label: 'No',
      },
    ],
  },
  {
    id: 'q12',
    img: require('@/assets/images/questionnaire-assets/q12-questionnaire-desktop.png'),
    imgMobile: require('@/assets/images/questionnaire-assets/q12-questionnaire-mobile.jpg'),
    flag: 'orange',
    label: 'Would you describe yourself as having strong attention to detail and well organised?',
    options: [
      {
        responseIndex: 1,
        label: 'Yes',
      },
      {
        responseIndex: 2,
        label: 'No',
      },
    ],
  },
  {
    id: 'q13',
    img: require('@/assets/images/questionnaire-assets/q13-questionnaire-desktop.png'),
    imgMobile: require('@/assets/images/questionnaire-assets/q13-questionnaire-mobile.jpg'),
    flag: 'yellow',
    label: 'Have you got managerial experience, or would you have an interest in managing a team?',
    options: [
      {
        responseIndex: 1,
        label: 'Yes',
      },
      {
        responseIndex: 2,
        label: 'No',
      },
    ],
  },
  {
    id: 'q14',
    img: require('@/assets/images/questionnaire-assets/q14-questionnaire-desktop.png'),
    imgMobile: require('@/assets/images/questionnaire-assets/q14-questionnaire-mobile.jpg'),
    flag: 'orange',
    label: "Does the following statement describe you? 'I'm comfortable working in a dynamic, changing environment?",
    options: [
      {
        responseIndex: 1,
        label: 'Yes',
      },
      {
        responseIndex: 2,
        label: 'No',
      },
    ],
  },
  {
    id: 'q15',
    img: require('@/assets/images/questionnaire-assets/q15-questionnaire-desktop.png'),
    imgMobile: require('@/assets/images/questionnaire-assets/q15-questionnaire-mobile.jpg'),
    flag: 'yellow',
    label: 'How do you prefer to work with others?',
    help: 'Use the scale below to state your preference. You can use the key for guidance.',
    options: [
      {
        responseIndex: 1,
        label: 'I prefer to work alone and would be comfortable isolated from others for the working day.',
      },
      {
        responseIndex: 2,
        label: 'I prefer working alone, but am happy to work directly with one or two colleagues.',
      },
      {
        responseIndex: 3,
        label: 'I like to be social, but don’t like large groups. I’m best as part of a small, close-knit team.',
      },
      {
        responseIndex: 4,
        label: 'I\'m happy working as part of a large team.',
      },
      {
        responseIndex: 5,
        label: 'I’m a people person. I love to be social, like larger groups, and am happy collaborating with lots of different people.',
      },
    ],
  },
  {
    id: 'q16',
    img: require('@/assets/images/questionnaire-assets/q16-questionnaire-desktop.png'),
    imgMobile: require('@/assets/images/questionnaire-assets/q16-questionnaire-mobile.jpg'),
    flag: 'orange',
    label: 'Does the following statement describe you?',
    help: 'I’m extremely sociable and great at building new relationships. I’d be happy spending the day talking with clients, customers, and contractors.',
    options: [
      {
        responseIndex: 1,
        label: 'Yes – I love working with customers and am happy in a customer-facing environment',
      },
      {
        responseIndex: 2,
        label: 'No – I’d prefer not to work with customers',
      },
    ],
  },
  {
    id: 'q17',
    img: require('@/assets/images/questionnaire-assets/q17-questionnaire-desktop.png'),
    imgMobile: require('@/assets/images/questionnaire-assets/q17-questionnaire-mobile.jpg'),
    flag: 'yellow',
    label: 'We have a variety of opportunities to provide support to others. Which of the following statements best applies to you?',
    options: [
      {
        responseIndex: 1,
        label: 'I want to provide medical support to my colleagues.',
      },
      {
        responseIndex: 2,
        label: 'I’d love to provide employment and personal support to my colleagues.',

      },
      {
        responseIndex: 3,
        label: 'I support my colleagues indirectly by creating processes to keep people safe.',
      },
      {
        responseIndex: 4,
        label: 'I’m happy to support my colleagues, but am not people-orientated.',
      },
    ],
  },
];

export default Questions;
