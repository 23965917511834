import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-12a15724"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content-box" }
const _hoisted_2 = {
  key: 0,
  class: "content-box__title"
}
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.content.title)
      ? (_openBlock(), _createElementBlock("h2", _hoisted_2, _toDisplayString(_ctx.content.title), 1))
      : _createCommentVNode("", true),
    (_ctx.content.body)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          innerHTML: _ctx.content.body,
          class: "content-box__body"
        }, null, 8, _hoisted_3))
      : _createCommentVNode("", true),
    (_ctx.content.buttonText && _ctx.content.buttonUrl && !_ctx.content.externalUrl)
      ? (_openBlock(), _createBlock(_component_router_link, {
          key: 2,
          class: "btn btn-secondary",
          to: _ctx.content.buttonUrl
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.content.buttonText), 1)
          ]),
          _: 1
        }, 8, ["to"]))
      : _createCommentVNode("", true),
    (_ctx.content.buttonText && _ctx.content.buttonUrl && _ctx.content.externalUrl)
      ? (_openBlock(), _createElementBlock("a", {
          key: 3,
          href: _ctx.content.buttonUrl,
          target: "_blank",
          rel: "noopener",
          class: "btn btn-secondary"
        }, _toDisplayString(_ctx.content.buttonText), 9, _hoisted_4))
      : _createCommentVNode("", true)
  ]))
}