import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Introduction = _resolveComponent("Introduction")!
  const _component_ContentBanner = _resolveComponent("ContentBanner")!
  const _component_JobSectors = _resolveComponent("JobSectors")!
  const _component_JobsService = _resolveComponent("JobsService")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Introduction),
    _createVNode(_component_ContentBanner, { content: _ctx.takeQuestionnaireContent }, null, 8, ["content"]),
    _createVNode(_component_JobSectors),
    _createVNode(_component_JobsService),
    _createVNode(_component_Footer)
  ], 64))
}