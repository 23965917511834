
import { defineComponent } from 'vue';
import Introduction from '@/components/Introduction.vue';
import ContentBanner from '@/components/ContentBanner.vue';
import JobSectors from '@/components/JobSectors.vue';
import JobsService from '@/components/JobsService.vue';
// import QuizContainer from '@/components/QuizContainer.vue';
import Footer from '@/components/Footer.vue';
import { TakeQuestionnaireContent } from '@/content/HomeContent';
import PlainContent from '@/types/PlainContent';

export default defineComponent({
  name: 'Home',
  components: {
    Introduction,
    ContentBanner,
    JobSectors,
    JobsService,
    // QuizContainer,
    Footer,
  },
  setup() {
    const takeQuestionnaireContent: PlainContent = TakeQuestionnaireContent;

    return {
      takeQuestionnaireContent,
    };
  },
});
