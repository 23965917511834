import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e91739f6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "content-container" }
const _hoisted_3 = {
  key: 0,
  class: "title"
}
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("section", {
    class: _normalizeClass({'inverted': _ctx.inverted})
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.content.title)
          ? (_openBlock(), _createElementBlock("h2", _hoisted_3, _toDisplayString(_ctx.content.title), 1))
          : _createCommentVNode("", true),
        (_ctx.content.body)
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              innerHTML: _ctx.content.body,
              class: "body"
            }, null, 8, _hoisted_4))
          : _createCommentVNode("", true),
        (_ctx.content.buttonText && _ctx.content.buttonUrl)
          ? (_openBlock(), _createBlock(_component_router_link, {
              key: 2,
              class: "btn btn-primary",
              to: _ctx.content.buttonUrl
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.content.buttonText), 1)
              ]),
              _: 1
            }, 8, ["to"]))
          : _createCommentVNode("", true)
      ])
    ])
  ], 2))
}