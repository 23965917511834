/* eslint-disable global-require */
/* eslint-disable max-len */
import PlainContent from '@/types/PlainContent';
import SectorCard from '@/types/SectorCard';

export const TakeQuestionnaireContent: PlainContent = {
  title: 'Take our Questionnaire',
  body: `<p>
          With over 400 job roles available, ranging from office-based to
          site-based, and apprentice level to highly skilled, you’re spoilt for
          choice. Let us help you narrow it down.
        </p>
        <p>
          <strong>
            Simply answer a few questions about your working preferences and we’ll
            point you in the right direction.
          </strong>
        </p>`,
  buttonText: 'Begin',
  buttonUrl: '/how-can-we-help',
};

export const IntroductionContent: PlainContent = {
  body: `<p>
          Hinkley Point C is more than just a construction project; it is a unique opportunity to grow and develop people’s skills and longer-term careers.
        </p>
        <p>
          We're building upon our contribution to the Country's move to net zero and supporting people and businesses across the South West region and beyond.
        </p>
        <p>
          No experience? No problem! We've already supported the training of over 750 apprentices through 50 different apprenticeship schemes and helped provide training for over 14,000 people. We're committed to making roles as accessible as possible.
        </p>
        <p>
          <strong>
            We want you to be part of this exciting project.
          </strong>
        </p>`,
};

export const JobServiceContent: PlainContent = {
  title: 'The Jobs Service',
  body: `<p>
          Excited and want to jump straight to it? Our Jobs Service is dedicated to making employment opportunities as accessible as possible.
        </p> 
        <p>
          Use our service to register your interest in future job opportunities, training opportunities, and to request information from our team.
        </p>`,
  buttonText: 'Visit the Jobs Service',
  buttonUrl: 'https://www.edfenergy.com/energy/nuclear-new-build-projects/hinkley-point-c/jobs-and-training',
  externalUrl: true,
};

export const JobSectorContent: PlainContent = {
  title: 'Explore our Job Sectors',
  body: `<p>
          Curious about the opportunities available? Each of our job sectors contain a variety of diverse and extensive job families.
        </p> 
        <p>
          We're much more than just construction, and the range of roles available may surprise you!
        </p>
        <p>
          <strong>
            You can use the links below to learn more about each sector, and the families within them.
          </strong>
        </p>`,
};

export const CardsContent: SectorCard[] = [
  {
    id: 1,
    imgPath: require('@/assets/images/landing-page-assets/020-1-icon-site-operations.png'),
    title: 'Site<br> Operations',
    url: '/job-sector/site-operations',
    linkText: 'Explore',
  },
  {
    id: 2,
    imgPath: require('@/assets/images/landing-page-assets/020-2-icon-civil-construction.png'),
    title: 'Civil<br> Construction',
    url: '/job-sector/civil-construction',
    linkText: 'Explore',
  },
  {
    id: 3,
    imgPath: require('@/assets/images/landing-page-assets/020-3-icon-MandE.png'),
    title: 'Mechanical & Electrical (M&E)',
    url: '/job-sector/mechanical-electrical',
    linkText: 'Explore',
  },
  {
    id: 4,
    imgPath: require('@/assets/images/landing-page-assets/020-4-icon-support-roles.png'),
    title: 'Support<br> Roles',
    url: '/job-sector/support-roles',
    linkText: 'Explore',
  },
];
