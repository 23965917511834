
import { defineComponent } from 'vue';
import Card from './Card.vue';
import SectorCard from '../types/SectorCard';
import {
  CardsContent,
  JobSectorContent,
} from '../content/HomeContent';
import PlainContent from '@/types/PlainContent';

export default defineComponent({
  name: 'JobSectors',
  components: {
    Card,
  },
  setup() {
    const cards: SectorCard[] = CardsContent;
    const jobSectorContent: PlainContent = JobSectorContent;

    return {
      cards,
      jobSectorContent,
    };
  },
});
