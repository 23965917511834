import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-300b042a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-container" }
const _hoisted_3 = ["src"]
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: _ctx.imgPath,
        alt: ""
      }, null, 8, _hoisted_3),
      _createElementVNode("h3", { innerHTML: _ctx.title }, null, 8, _hoisted_4),
      _createVNode(_component_router_link, {
        class: "btn btn-primary",
        to: _ctx.url
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.linkText), 1)
        ]),
        _: 1
      }, 8, ["to"])
    ])
  ]))
}