import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import Home from '../views/Home.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/how-can-we-help',
    name: 'HowCanWeHelp',
    // route level code-splitting
    // this generates a separate chunk (how.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "how can we help" */ '../views/HowCanWeHelp.vue'),
  },
  {
    path: '/quiz',
    name: 'Quiz',
    component: () => import('../views/Quiz.vue'),
  },
  {
    path: '/quiz-results',
    name: 'QuizResults',
    component: () => import('../views/QuizResults.vue'),
  },
  {
    path: '/questionnaire',
    name: 'Questionnaire',
    component: () => import('../views/Questionnaire.vue'),
  },
  {
    path: '/questionnaire-results',
    name: 'QuestionnaireResults',
    component: () => import('../views/QuestionnaireResults.vue'),
  },
  {
    path: '/qualifications',
    name: 'Qualifications',
    component: () => import('../views/Qualifications.vue'),
  },
  {
    path: '/feedback',
    name: 'Feedback',
    component: () => import('../views/Feedback.vue'),
  },
  {
    path: '/job-sector/:sector',
    name: 'JobSector',
    component: () => import('../views/JobSector.vue'),
  },
  {
    path: '/job-families',
    name: 'JobFamilies',
    component: () => import('../views/JobFamilies.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: () => import('../views/NotFound.vue'),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      };
    }
    return { left: 0, top: 0 };
  },
});

router.beforeEach((to, from, next) => {
  document.title = 'EDF Energy HPC recruitment tool';
  next();
});

export default router;
