import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-40eb6309"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "job-sectors" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "content-container" }
const _hoisted_4 = { class: "content-container__title" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "grid-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h2", _hoisted_4, _toDisplayString(_ctx.jobSectorContent.title), 1),
        _createElementVNode("div", {
          class: "content-container__body",
          innerHTML: _ctx.jobSectorContent.body
        }, null, 8, _hoisted_5)
      ]),
      _createElementVNode("div", _hoisted_6, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cards, (card) => {
          return (_openBlock(), _createBlock(_component_Card, {
            key: card.id,
            imgPath: card.imgPath,
            title: card.title,
            url: card.url,
            linkText: card.linkText
          }, null, 8, ["imgPath", "title", "url", "linkText"]))
        }), 128))
      ])
    ])
  ]))
}