
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Card',
  props: {
    imgPath: {
      required: true,
      type: String,
    },
    title: {
      required: true,
      type: String,
    },
    url: {
      required: true,
      type: String,
    },
    linkText: {
      required: true,
      type: String,
    },
  },
});
