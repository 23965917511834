
import { defineComponent } from 'vue';
import ContentBox from './ContentBox.vue';
import { JobServiceContent } from '@/content/HomeContent';
import PlainContent from '@/types/PlainContent';

export default defineComponent({
  name: 'JobsService',
  components: {
    ContentBox,
  },
  setup() {
    const jobServiceContent: PlainContent = JobServiceContent;

    return {
      jobServiceContent,
    };
  },
});
